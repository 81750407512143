import {request} from "../request";
//请求表格数据
export function tableUserMsg(data){
  return request({
    url:'user/getStaffs',
    method:'get',
    params:data
  })
}
//添加用户
export function addUserMsg(data){
  return request({
    url:'user/addUser',
    method:'post',
    data:data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//删除用户
export function deleteUserMsg(data){
  return request({
    url:'user/deleteStaff',
    method:'post',
    params:data,
  })
}
//更新用户数据
export function updateUserMsg(data){
  return request({
    url:'user/updateStaff',
    method:'post',
    data:data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
//查询所有家庭住址
export function allAddress(){
  return request({
    url:'address/selectAllAddress',
    method:'get'
  })
}
//查询所有工作住址
export function workPlaces(){
  return request({
    url:'workPlace/selectAllWorkPlaces',
    method:'get'
  })
}
// 查询所有管理辖区
export function allProvince(){
  return request({
    url:'province/selectAllProvince',
    method:'get'
  })
}
// 查询所有角色
export function allRoles(){
  return request({
    url:'role/selectAllRoles',
    method:'get'
  })
}