<template>
  <div class="table_container">
<!--    通知框-->
    <v-snackbar
        timeout="2000"
        :value="snackbarShow"
        :color="snackbarColor"
        absolute
        top
        v-model="snackbarShow"
    >{{snackbarText}}
    </v-snackbar>
    <!--    表-->
    <v-data-table
        :loading="loading"
        loading-text="加载中,请稍等..."
        :headers="headers"
        :items="desserts"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1"
        @page-count="pageCount = $event"
        height="600px"
        sort-by="id"
    >
<!--      标题栏-->
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>用户管理</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <!--添加用户-->
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="#1E88E5"
                  style="font-weight: bold"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                添加用户
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">用户信息</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          label="用户名"
                          v-model="addUserMsg.username"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          label="密码"
                          v-model="addUserMsg.password"
                          :value="addUserMsg.password"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-select
                          v-model="addUserMsg.workPlaceName"
                          :items="workPlaceList"
                          label="工作地点"
                      ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          v-model="addUserMsg.managerName"
                          label="上级"
                          :value="addUserMsg.managerName"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-select
                          v-model="addUserMsg.roleName"
                          :items="roleList"
                          label="权限"
                      ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                    >
                      <v-autocomplete
                          :items="jurisdictionList"
                          label="管理辖区"
                          multiple
                          v-model="addUserMsg.jurisdiction"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="dialog = false"
                >
                  取消
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="add"
                >
                  添加
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.exit ="{ item }">
        <v-btn
            dark
            color="#0288D1"
            small
            style="font-weight: bold"
            @click="exit(item)"
        >编辑</v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn
            dark
            color="#EF5350"
            small
            style="font-weight: bold"
            @click="showDialog(item)"
        >删除</v-btn>
      </template>
    </v-data-table>
    <!--    页码-->
    <div class="text-center pt-4">
      <v-pagination
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
<!--    编辑-->
    <v-dialog
        v-model="dialog2"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">用户信息</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    label="用户名"
                    readonly
                    :value="editUserMsg.username"
                    v-model="editUserMsg.username"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    type="date"
                    label="出生日期"
                    :value="editUserMsg.birthday"
                    v-model="editUserMsg.birthday"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-select
                    :items="['男','女']"
                    label="性别"
                    :value="editUserMsg.sex"
                    v-model="editUserMsg.sex"
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    :value="editUserMsg.email"
                    v-model="editUserMsg.email"
                    label="邮箱"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-select
                    :items="allAddressList"
                    label="地址"
                    :value="editUserMsg.addressName"
                    v-model="editUserMsg.addressName"
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    :value="editUserMsg.telephone"
                    v-model="editUserMsg.telephone"
                    label="手机"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-select
                    :items="workPlaceList"
                    label="工作地点"
                    :value="editUserMsg.workPlaceName"
                    v-model="editUserMsg.workPlaceName"
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-text-field
                    readonly
                    :value="editUserMsg.managerName"
                    v-model="editUserMsg.managerName"
                    label="上级"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-select
                    :items="roleList"
                    label="权限"
                    :value="editUserMsg.roleName"
                    v-model="editUserMsg.roleName"
                ></v-select>
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <v-autocomplete
                    :items="jurisdictionList"
                    label="管理辖区"
                    multiple
                    :value="editUserMsg.jurisdiction"
                    v-model="editUserMsg.jurisdiction"
                ></v-autocomplete>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="blue darken-1"
              text
              @click="dialog2 = false"
          >
            取消
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!--提示删除-->
    <v-dialog
        max-width="400"
        v-model="dialog3"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
              color="primary"
              dark
          >提示</v-toolbar>
          <v-card-text>
            <div class="text-h6 mt-4">确认删除该账户的所有信息?</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog3 = false"
            >
              取消
            </v-btn>
            <v-btn
                color="#EF5350"
                text
                @click="deleteMsg"
            >
              确认
            </v-btn>

          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import {tableUserMsg,addUserMsg,deleteUserMsg,updateUserMsg,
  allAddress,allProvince,allRoles,workPlaces} from "../../network/Details/userManage";
export default {
  name: "UserManage",
  data () {
    return {
      loading:true,
      dialog3:false,
      workPlaceList:'',
      jurisdictionList:[],
      allAddressList:'',
      roleList:'',
      username:'',
      dialog: false,
      dialog2: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: '编号',
          align: 'start',
          sortable: true,
          value: 'userID',
        },
        { text: '用户名', value: 'username' },
        { text: '出生日期', value: 'birthday' },
        { text: '性别', value: 'sex' },
        { text: '电话', value: 'telephone' },
        { text: '管理辖区', value: 'jurisdiction' },
        { text: '权限', value: 'roleName' },
        { text: '全部信息', value: 'exit' },
        { text: '删除账号', value: 'delete' },
      ],
      desserts: [],
      //添加用户信息
      addUserMsg:{
        username:'',
        password:'123456',
        workPlaceName:'',
        managerName:'',
        roleName:'',
        jurisdiction:[]
      },
      //编辑用户信息
      editUserMsg:{},
      tempEdit:{},
      //提示
      snackbarShow:false,
      snackbarText:'',
      snackbarColor:'#42A5F5',
    }
  },
  methods:{
    //点击添加按钮
    add(){
      this.dialog = false;
      addUserMsg(JSON.stringify(this.addUserMsg)).then(res => {
        this.snackbarShow = true;
        this.snackbarText = "添加用户成功！";
        this.updateTable();
      })
    },
    //保存修改
    save(){
      let dataList = {};
      let flag = 0;
      this.tempEdit = JSON.parse(window.sessionStorage.getItem("tempEdit"));
      dataList.userID = this.editUserMsg.userID;
      dataList.jurisdiction = this.editUserMsg.jurisdiction;
      if(this.tempEdit.roleName != this.editUserMsg.roleName){
        dataList.roleName = this.editUserMsg.roleName;
        flag++;
      }
      if(this.tempEdit.sex != this.editUserMsg.sex){
        dataList.sex = this.editUserMsg.sex;
        flag++;
      }
      if(this.tempEdit.birthday != this.editUserMsg.birthday){
        dataList.birthday = this.editUserMsg.birthday;
        flag++;
      }
      if(this.tempEdit.addressName != this.editUserMsg.addressName){
        dataList.addressName = this.editUserMsg.addressName;
        flag++;
      }
      if(this.tempEdit.telephone != this.editUserMsg.telephone){
        dataList.telephone = this.editUserMsg.telephone;
        flag++;
      }
      if(this.tempEdit.email != this.editUserMsg.email){
        dataList.email = this.editUserMsg.email;
        flag++;
      }
      if(this.tempEdit.orkPlaceName != this.editUserMsg.orkPlaceName){
        dataList.orkPlaceName = this.editUserMsg.orkPlaceName;
        flag++;
      }
      console.log(flag)
      if(flag > 0){
        console.log(JSON.stringify(dataList));
        updateUserMsg(JSON.stringify(dataList)).then(res => {
          this.updateTable();
          this.snackbarShow = true;
          this.snackbarText = res.message;
        });
      }
      sessionStorage.removeItem('tempEdit');
      this.dialog2 = false;
    },
    //点击编辑
    exit(item){
      this.editUserMsg = item;
      window.sessionStorage.setItem('tempEdit',JSON.stringify(item));
      this.dialog2 = true;
    },
    //删除弹窗
    showDialog(item){
      this.dialog3 = true;
      this.tempItem = item;
    },
    //点击删除
    deleteMsg(){
      deleteUserMsg({
        username:this.tempItem.username
      }).then(res => {
        this.updateTable();
        console.log(res);
        this.snackbarShow = true;
        this.snackbarText = res.message;
      });
      this.dialog3 = false;
    },
    //更新表格数据
    updateTable( ){
      tableUserMsg({
        username:this.username
      }).then(res => {
        this.desserts = res.data;
        this.loading = false;
      })
    },
  },
  created() {
    allAddress().then(res => {
      this.allAddressList = res.data;
    });
    allProvince().then(res => {
      this.jurisdictionList = res.data;
    });
    allRoles().then(res => {
      this.roleList = res.data;
    });
    workPlaces().then(res => {
      this.workPlaceList = res.data;
    });
    this.username = window.localStorage.getItem('username');
    this.addUserMsg.managerName = this.username;
    this.updateTable();
  },
}
</script>

<style lang="less" scoped>
.table_container{
  margin-top: 2%;
  width: 94%;
  margin-left: 3%;
}
</style>